<template>
    <div>
      <Alert status="primary" fontSize="lg" v-if="showSetup">
          <b>Attenzione!</b> Il credito o il debito IVA previsto per il periodo in corso, mese o trimestre, potrebbe non essere indicativo della situazione reale. Una stima corretta necessita di almeno un periodo completo di flusso costante di informazioni.
      </Alert>
      <Alert status="primary" fontSize="lg" v-if="!showSetup">
        <b>Attenzione!</b> La modifica della periodicità avverrà solamente alla fine dell'esercizio corrente.
      </Alert>

      <div class="row my-4">
          <div class="col">
              <h5 class="mb-3">Periodicità IVA</h5>
          </div>
          <div class="col-6 d-flex justify-content-end align-items-center mb-2">
            <label class="mr-3 mb-0" for="selector">
              Modalità
            </label>
            <div class="selector" id="selector">
              <button class="btn option" @click="setView('month')" :class="{ 'active': form.periodicity === 'month' }">
                Mensile
              </button>
              <button class="btn option" @click="setView('quarter')" :class="{ 'active': form.periodicity === 'quarter' }">
                Trimestrale
              </button>
            </div>
          </div>
      </div>

      <div v-if="showSetup">
        <div class="row">
            <div class="col-4" :class="{ 'd-none': !showDebt }">
              <b-form-group
                id="input-group-debt"
                label="Debito IVA periodo precedente"
                :description="labelExpiration"
                label-for="input-debt"
                :class="{ 'invalid': !isValidDebt && showErrorDebt }"
              >
                <CurrencyInput v-model="form.debt" @blur="showErrorDebt = true" id="input-debt" :key="`debt_${update}`"/>
                <b-form-invalid-feedback :state="false" :class="{ 'invisible': isValidDebt || !showErrorDebt }" >
                  <i class="fas fa-exclamation-circle"></i> Inserisci un importo valido
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="col-4">
              <b-form-group
                id="input-group-credit"
                :label="labelCredit"
                label-for="input-credit"
                :class="{ 'invalid': !isValidCredit && showErrorCredit }"
              >
                <CurrencyInput v-model="form.credit" @blur="showErrorCredit = true" id="input-credit" :key="`credit_${update}`"/>
                <b-form-invalid-feedback :state="false" :class="{ 'invisible': isValidCredit || !showErrorCredit }" >
                  <i class="fas fa-exclamation-circle"></i> Inserisci un importo valido
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="col-4">
              <b-form-group
                id="input-group-balance"
                :label="labelBalance"
                label-for="input-balance"
                :class="{ 'invalid': !isValidBalance && showErrorBalance }"
              >
                <CurrencyInput v-model="form.balance" @blur="showErrorBalance = true" id="input-balance" :key="`balance_${update}`"/>
                <b-form-invalid-feedback :state="false" :class="{ 'invisible': isValidBalance || !showErrorBalance }" >
                  <i class="fas fa-exclamation-circle"></i> Inserisci un importo valido
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
        </div>

        <div class="row mt-4">
          <div class="col-8">
            <BankSelect ref="bankSelect" label="Banca pagamento" :banks="banks" :selectedItem="bank" @update="updateBank"/>
          </div>
        </div>

        <div class="row mt-5">
            <div class="col-4 ml-auto">
              <custom-button
                :isLoading="loading"
                :class="{'disabled': !isValidForm}"
                label="Salva"
                class="w-100"
                @click.prevent.native="onSubmit"
              />
            </div>
        </div>
      </div>
      <div v-else class="text-center m-5">
        <div class="mb-4">
          Ora puoi gestire l'IVA dalla pagina Gestione IVA
        </div>
        <router-link :to="{ name: 'iva' }" tag="button" class="btn btn-fin">
          Vai a Gestione IVA
        </router-link>
      </div>

    </div>
</template>

<script>
import { BFormGroup, BFormInvalidFeedback } from 'bootstrap-vue';
import { isFilled, isCurrency } from '@/helpers/formValidation.js';
import { toFormattedDate } from '@/helpers/dates.js';

import Alert from '@/libs/Feedback/components/Alert.vue';
import BankSelect from '@/views/components/BankSelect.vue';
import Button from '@/views/components/Button.vue';
import CurrencyInput from '@/views/components/CurrencyInput.vue';

export default {
  props: {
    companyId: String,
  },
  components: {
    Alert,
    BankSelect,
    BFormGroup,
    BFormInvalidFeedback,
    'custom-button': Button,
    CurrencyInput,
  },
  data () {
    return {
      loading: false,
      update: 0,
      showSetup: true,

      bank: null,
      banks: [],

      previousExpirationDate: null,
      creditStartDate: null,
      showDebt: null,

      showErrorDebt: false,
      showErrorCredit: false,
      showErrorBalance: false,

      form: {
        debt: null,
        credit: null,
        balance: null,
        bank: null,
        periodicity: 'month',
      },
    };
  },
  computed: {
    isValidForm () {
      // return true;
      return (this.isValidCredit || this.isValidDebt) && this.isValidBalance;
    },
    isValidDebt () {
      // return true;
      return isFilled(this.form.debt) && isCurrency(this.form.debt) && this.form.balance >= 0;
    },
    isValidCredit () {
      // return true;
      return isFilled(this.form.credit) && isCurrency(this.form.credit) && this.form.balance >= 0;
    },
    isValidBalance () {
      // return true;
      return isFilled(this.form.balance) && isCurrency(this.form.balance) && this.form.balance >= 0;
    },
    labelExpiration () {
      /*
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      if (this.form.periodicity === 'month' && today.getDate() <= 16) {
        const date = new Date(today.getFullYear(), today.getMonth(), 16);
        return `In scadenza il ${this.toFormattedDate(date)}`;
      } else if (this.form.periodicity === 'quarter') {
        const quarterDates = [
          { start: new Date(today.getFullYear(), 0, 1), end: new Date(today.getFullYear(), 2, 16) }, // 01/01 - 16/03
          { start: new Date(today.getFullYear(), 3, 1), end: new Date(today.getFullYear(), 4, 16) }, // 01/04 - 16/05
          { start: new Date(today.getFullYear(), 5, 1), end: new Date(today.getFullYear(), 7, 20) }, // 01/06 - 20/08
          { start: new Date(today.getFullYear(), 8, 1), end: new Date(today.getFullYear(), 10, 16) }, // 01/09 - 16/11
        ];

        for (const quarter of quarterDates) {
          if (today >= quarter.start && today <= quarter.end) {
            return `In scadenza il ${this.toFormattedDate(quarter.end)}`;
          }
        }
      }
      */
      return `Credito IVA al ${this.toFormattedDate(this.previousExpirationDate)}`;
    },
    labelCredit () {
      /*
      const today = new Date();
      if (this.form.periodicity === 'month') {
        const date = new Date(today.getFullYear(), today.getMonth(), 1);
        return `Credito  al ${this.toFormattedDate(date)}`;
      }
      if (this.form.periodicity === 'quarter') {
        const quarterDates = [
          { start: new Date(today.getFullYear(), 0, 1), end: new Date(today.getFullYear(), 2, 31) }, // 01/01 - 31/03
          { start: new Date(today.getFullYear(), 3, 1), end: new Date(today.getFullYear(), 4, 31) }, // 01/04 - 31/05
          { start: new Date(today.getFullYear(), 5, 1), end: new Date(today.getFullYear(), 8, 30) }, // 01/06 - 30/09
          { start: new Date(today.getFullYear(), 9, 1), end: new Date(today.getFullYear(), 11, 31) }, // 01/10 - 16/11
        ];

        for (const quarter of quarterDates) {
          if (today >= quarter.start && today <= quarter.end) {
            return `Credito IVA al ${this.toFormattedDate(quarter.start)}`;
          }
        }
      }
      */
      return `Credito IVA al ${this.toFormattedDate(this.creditStartDate)}`;
    },
    labelBalance () {
      const date = new Date();
      const year = date.getFullYear() - 1;
      return 'Saldo IVA ' + year;
    },
  },
  methods: {
    getCompanyInfo () {
      this.$api.getCompany(this.companyId)
        .then((res) => {
          this.$log.debug(res.data);
          const company = res.data;
          this.banks = company.banks.filter(obj => !obj.isCash);
        })
        .catch((e) => {
          this.$log.error(e);
        });
    },
    setView (type) {
      const oldValue = this.form.periodicity;
      this.form.periodicity = type;
      this.getExpirationDate();

      if (!this.showSetup) {
        this.$api.setPeriodicityIVA(this.companyId, this.form.periodicity)
          .then((res) => {
            this.$log.debug(res.data);
            this.snackbarText = "La periodicità verrà aggiornata alla fine dell'esercizio corrente";
            this.$emit('openSnackbar', this.snackbarText);
          })
          .catch((e) => {
            this.$log.error(e);
            this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Riprova o contatta l'assistenza se il problema persiste";
            this.$emit('setError', this.errorMessage);
            // set back the last option
            this.form.periodicity = oldValue;
          })
          .finally(() => {
            this.update++;
          });
      }
    },
    getExpirationDate () {
      this.$api.getDatesIVA(this.form.periodicity)
        .then((res) => {
          this.previousExpirationDate = res.data.previousExpirationDate;
          this.creditStartDate = res.data.creditStartDate;
          this.showDebt = !!this.previousExpirationDate;

          this.update++;
        })
        .catch((e) => {
          this.$log.error(e);
        });
    },
    getIVA () {
      this.$api.getIVA(this.companyId)
        .then((res) => {
          this.showSetup = res.data.iva === null;
          this.form.periodicity = res.data?.iva?.periodicity ? res.data.iva.periodicity : 'month';
          this.getExpirationDate();
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Riprova o contatta l'assistenza se il problema persiste";
          this.$emit('setError', this.errorMessage);
        })
        .finally(() => {
          this.update++;
        });
    },
    onSubmit () {
      if (this.isValidForm) {
        this.saveData();
      } else {
        this.loading = true;
        if (!this.isValidDebt) {
          this.showErrorDebt = true;
        } else if (!this.isValidCredit) {
          this.showErrorCredit = true;
        } else if (!this.isValidBalance) {
          this.showErrorBalance = true;
        }
        setTimeout(() => {
          this.loading = false;
        }, 200);
      }
    },
    toFormattedDate (s) {
      return toFormattedDate(s);
    },
    saveData () {
      this.$emit('setError', '');
      this.$api.setIVA(this.companyId, this.form)
        .then((res) => {
          this.$log.debug(res);
          this.snackbarText = 'Informazioni salvate';
          this.$emit('openSnackbar', this.snackbarText);
          this.getIVA();
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = e.response.data.msg ? e.response.data.msg : "Qualcosa è andato storto. Controlla i dati inseriti o contatta l'assistenza se il problema persiste";
          this.$emit('setError', this.errorMessage);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateBank (bank) {
      this.form.bank = bank;
    },
  },
  mounted () {
    this.getCompanyInfo();
    this.getIVA();
  },
};
</script>

  <style>

  </style>
